var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contant d-flex flex-column align-center",class:{ mobile: _vm.isMobile }},[_c('div',{staticClass:"d-flex flex-lg-row flex-column align-center"},[_c('div',{staticClass:"left"},[(!_vm.isMobile)?_c('img',{attrs:{"src":require("@/assets/logo1.png"),"height":"90","alt":""}}):_c('img',{attrs:{"src":require("@/assets/mobile/m_logo1.png"),"width":"80%","alt":""}}),_c('div',{staticClass:"des mt-7"},[_vm._v(" Your multi-chain, cross-chain crypto wallet for managing a diverse range of fungible tokens (FTs), non-fungible tokens (NFTs), and real-world assets (RWAs). ")]),_c('div',{staticClass:"download mt-8"},[_c('div',{staticClass:"download d-flex flex-row"},[_c('img',{attrs:{"src":require(_vm.isMobile
                ? '@/assets/d_android.png'
                : '@/assets/d_android1.png'),"height":"50","alt":""},on:{"mouseenter":function($event){return _vm.mousedAndroid()},"mouseleave":function($event){return _vm.leaveAndroid()},"click":_vm.downAndroid}}),_c('img',{staticClass:"ml-6 testflight",attrs:{"src":require(_vm.isMobile
                ? '@/assets/d_googleplay.png'
                : '@/assets/d_googleplay1.png'),"height":"50","alt":""},on:{"mouseenter":function($event){return _vm.mousedGoogle()},"mouseleave":function($event){return _vm.leaveGoogle()},"click":_vm.downGoogle}}),_c('img',{staticClass:"testflight ml-6",attrs:{"src":require(_vm.isMobile
                ? '@/assets/d_appstore.png'
                : '@/assets/d_appstore1.png'),"height":"50","alt":""},on:{"mouseenter":function($event){return _vm.mousedAppleStore()},"mouseleave":function($event){return _vm.leaveAppleStore()},"click":_vm.downAppstore}})]),(_vm.isShowAndroid && !_vm.isMobile)?_c('img',{staticClass:"android_code",attrs:{"src":require("@/assets/download_android.png"),"width":"155","alt":""}}):_vm._e(),(_vm.isShowIos && !_vm.isMobile)?_c('img',{staticClass:"ios_code",attrs:{"src":require("@/assets/download_ios.png"),"width":"155","alt":""}}):_vm._e(),(_vm.isShowFGoogle && !_vm.isMobile)?_c('img',{staticClass:"google_code",attrs:{"src":require("@/assets/download_google.png"),"width":"155","alt":""}}):_vm._e(),(_vm.isShowAppleStore && !_vm.isMobile)?_c('img',{staticClass:"apple_code",attrs:{"src":require("@/assets/download_appstore.png"),"width":"155","alt":""}}):_vm._e()])]),_c('div',{staticClass:"right"},[(!_vm.isMobile)?_c('img',{attrs:{"src":require("@/assets/bg1.png"),"height":"600","alt":""}}):_c('img',{attrs:{"src":require("@/assets/bg1.png"),"height":"502","width":"100%","alt":""}})])]),_vm._m(0),_c('div',{staticClass:"upwardDes mt-5"},[_c('div',{staticClass:"title-35 mb-14"},[_vm._v("Key Features")]),_c('div',{staticClass:"hightItem"},_vm._l((_vm.hightLightList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('img',{staticClass:"iconImage",attrs:{"src":require('@/assets/' + item.image),"alt":""}}),_c('div',{staticClass:"itemRight ml-7"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"description mt-1"},[_vm._v(_vm._s(item.description))])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upwardDes"},[_c('div',{staticClass:"title-35"},[_vm._v("Why Upward Wallet?")]),_c('div',{staticClass:"title-18 mt-8"},[_vm._v(" Upward Wallet is a non-custodial digital wallet built for smooth integration into the Uptick Network Ecosystem. It supports interchain interoperability for fungible and non-fungible tokens, making it easy to manage assets across multiple blockchains. ")]),_c('div',{staticClass:"title-18 mt-5"},[_vm._v(" Designed for speed, accessibility, and effortless Web3 integration. ")]),_c('img',{staticClass:"uptickImage",attrs:{"src":require("@/assets/image_01.png"),"alt":""}})])
}]

export { render, staticRenderFns }