<template>
  <div
    class="contant d-flex flex-column align-center"
    :class="{ mobile: isMobile }"
  >
    <div class="d-flex flex-lg-row flex-column align-center">
      <div class="left">
        <img v-if="!isMobile" src="@/assets/logo1.png" height="90" alt="" />
        <img v-else src="@/assets/mobile/m_logo1.png" width="80%" alt="" />
        <div class="des mt-7">
          Your multi-chain, cross-chain crypto wallet for managing a diverse
          range of fungible tokens (FTs), non-fungible tokens (NFTs), and
          real-world assets (RWAs).
        </div>
        <div class="download mt-8">
          <div class="download d-flex flex-row">
            <img
              :src="
                require(isMobile
                  ? '@/assets/d_android.png'
                  : '@/assets/d_android1.png')
              "
              @mouseenter="mousedAndroid()"
              @mouseleave="leaveAndroid()"
              @click="downAndroid"
              height="50"
              alt=""
            />

            <img
              @mouseenter="mousedGoogle()"
              @mouseleave="leaveGoogle()"
              :src="
                require(isMobile
                  ? '@/assets/d_googleplay.png'
                  : '@/assets/d_googleplay1.png')
              "
              @click="downGoogle"
              class="ml-6 testflight"
              height="50"
              alt=""
            />
            <img
              @mouseenter="mousedAppleStore()"
              @mouseleave="leaveAppleStore()"
              :src="
                require(isMobile
                  ? '@/assets/d_appstore.png'
                  : '@/assets/d_appstore1.png')
              "
              @click="downAppstore"
              class="testflight ml-6"
              height="50"
              alt=""
            />
          </div>
          <!-- <div class="download mt-lg-4 mt-2">
            
            <img src="@/assets/d_testflight.png"   @click="downIos"    @mouseenter="mousedIos()"
      @mouseleave ="leaveIos()" class="ml-6 testflight"   height="50" alt="">
          </div> -->

          <img
            v-if="isShowAndroid && !isMobile"
            class="android_code"
            src="@/assets/download_android.png"
            width="155"
            alt=""
          />
          <img
            v-if="isShowIos && !isMobile"
            class="ios_code"
            src="@/assets/download_ios.png"
            width="155"
            alt=""
          />
          <img
            v-if="isShowFGoogle && !isMobile"
            class="google_code"
            src="@/assets/download_google.png"
            width="155"
            alt=""
          />
          <img
            v-if="isShowAppleStore && !isMobile"
            class="apple_code"
            src="@/assets/download_appstore.png"
            width="155"
            alt=""
          />
        </div>
      </div>
      <div class="right">
        <img v-if="!isMobile" src="@/assets/bg1.png" height="600" alt="" />
        <img v-else src="@/assets/bg1.png" height="502" width="100%" alt="" />
      </div>
    </div>
    <div class="upwardDes">
      <div class="title-35">Why Upward Wallet?</div>
      <div class="title-18 mt-8">
        Upward Wallet is a non-custodial digital wallet built for smooth
        integration into the Uptick Network Ecosystem. It supports interchain
        interoperability for fungible and non-fungible tokens, making it easy to
        manage assets across multiple blockchains.
      </div>
      <div class="title-18 mt-5">
        Designed for speed, accessibility, and effortless Web3 integration.
      </div>
      <img src="@/assets/image_01.png" class="uptickImage" alt="" />
    </div>
    <div class="upwardDes mt-5">
      <div class="title-35 mb-14">Key Features</div>
      <div class="hightItem">
        <div class="item" v-for="(item, index) in hightLightList" :key="index">
          <img
            :src="require('@/assets/' + item.image)"
            class="iconImage"
            alt=""
          />
          <div class="itemRight ml-7">
            <div class="name">{{ item.name }}</div>
            <div class="description mt-1">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data: function () {
    return {
      isShowAndroid: false,
      isShowIos: false,
      isShowFGoogle: false,
      isShowAppleStore: false,
      hightLightList: [
        {
          image: 'icon_01.png',
          name: 'Non-Custodial Security',
          description:
            'Own your assets, control your keys, complete security in your hands.',
        },

        {
          image: 'icon_02.png',
          name: 'Web3 Ecosystem Integration',
          description:
            'Easily connect with the world of decentralized applications.',
        },
        {
          image: 'icon_03.png',
          name: 'Social Account Integration',
          description: 'Create your wallet fast with familiar social logins.',
        },
        {
          image: 'icon_04.png',
          name: 'Built-in Faucet for New Users',
          description: 'Free gas to remove barriers and get newcomers started.',
        },
        {
          image: 'icon_05.png',
          name: 'Incentive & Rewards Mechanism',
          description: 'Get rewarded for engaging with the ecosystem.',
        },
        {
          image: 'icon_06.png',
          name: 'Comprehensive FT & NFT/RWA Support',
          description:
            'Support for a wide range of tokens, beyond ERC-20, ERC-721, ERC-1155, and ERC-1948.',
        },
        {
          image: 'icon_07.png',
          name: 'Complete EVM & Cosmos-SDK Chain Support',
          description:
            'Connect effortlessly with popular EVM and Cosmos chains, plus manual additions.',
        },
        {
          image: 'icon_08.png',
          name: 'Multi-Chain & Cross-Chain Interoperability',
          description:
            'Leverage Uptick Cross-Chain Bridge (UCB) and Inter-Blockchain Communication (IBC) for smooth asset transfer.',
        },
        {
          image: 'icon_09.png',
          name: 'Community Governance & Staking',
          description:
            'Simple tools for active participation in governance and staking.',
        },
        {
          image: 'icon_10.png',
          name: 'Omni-Payment Support for Ecosystem Applications',
          description:
            'Flexible payment integration and collaboration across the ecosystem.',
        },
      ],
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  async mounted() {},

  methods: {
    downGoogle() {
      window.open(
        'https://play.google.com/store/apps/details?id=com.uptick.wallet',
        '_blank'
      );
    },
    downAppstore() {
      window.open(
        'https://apps.apple.com/us/app/upward-wallet/id6472655450',
        '_blank'
      );
    },

    downAndroid() {
      window.open('https://file.uptick.network/app/Upwardwallet.apk', '_blank');
    },
    downIos() {
      window.open('https://testflight.apple.com/join/X5GWHcO1', '_blank');
    },
    mousedAndroid() {
      this.isShowAndroid = true;
    },
    leaveAndroid() {
      this.isShowAndroid = false;
    },
    mousedIos() {
      this.isShowIos = true;
    },
    leaveIos() {
      this.isShowIos = false;
    },
    mousedGoogle() {
      this.isShowFGoogle = true;
    },
    leaveGoogle() {
      this.isShowFGoogle = false;
    },
    mousedAppleStore() {
      this.isShowAppleStore = true;
    },
    leaveAppleStore() {
      this.isShowAppleStore = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contant {
  width: 100%;
  height: 100%;
  .left {
    .des {
      width: 555px;
      font-family: Helvetica;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      color: #ffffff;
    }
    .download {
      position: relative;
      img {
        cursor: pointer;
      }
      .android_code {
        position: absolute;
        top: -160px;
        left: 0px;
      }
      .ios_code {
        position: absolute;
        top: -160px;
        left: 270px;
      }
      .google_code {
        position: absolute;
        top: -160px;
        left: 190px;
      }
      .apple_code {
        position: absolute;
        top: -160px;
        left: 380px;
      }
    }
    .description {
      width: 487px;
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 1px;
      color: #ffffff;
      font-style: italic;
    }
  }
  .title-35 {
    font-family: Helvetica-Bold;
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .hightItem {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 90px;

    .item {
      display: flex;
      flex-direction: row;
      width: 40%;
      margin-right: 10%;
      margin-bottom: 50px;
      .iconImage {
        height: 70px;
      }
      .name {
        font-family: Helvetica-Bold;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .description {
        font-family: Helvetica;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #fd79d0;
      }
    }
  }
  .upwardDes {
    width: 95%;

    .title-18 {
      width: 100%;
      font-family: Helvetica;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 28px;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .uptickImage {
      width: 80%;
      margin: 0 10%;
      margin-top: 60px;
    }
  }

  &.mobile {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 90px;
    .uptickImage {
      width: 100%;
      margin: 50px 0;
    }
    .hightItem {
      .item {
        display: flex;
        flex-direction: row;
        width: 100%;

        margin-bottom: 50px;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      .des {
        width: 75%;
        text-align: center;
      }
      .description {
        width: 75%;
        text-align: center;
      }
      .testflight {
        margin-top: 8px;
        margin-left: 0px !important;
      }
      .download {
        display: flex !important;
        flex-direction: column !important;
        align-items: center;
        width: 100%;
        position: relative !important;
      }
    }
  }
}
</style>
