<template>
  <div class="foot" :class="{ mobile: isMobile }">
    <v-footer app absolute class="footer" :elevation="0" :height="height">
      <v-container class="contant-main d-flex flex-column align-center">
        <div class="information d-flex flex-row align-center mb-lg-11 mb-8">
          <div>
            <a
              class="tech"
              href="https://discord.com/channels/781005936260939818/943431354639601715"
              target="_blank"
              rel="noopener noreferrer"
              >Tech support ></a
            >
          </div>
          <div class="line ml-9 mr-8" v-if="!isMobile"></div>
          <div class="mediaInfo d-flex flex-row align-center">
            <div class="contract mr-5">Contact Us</div>
            <div
              class="d-flex justify-space-around align-center"
              style="width: 100%"
            >
              <a :href="`mailto:${mailurl}`" target="_blank">
                <img src="@/assets/icon_email.png" style="width: 30px" />
                <a :href="discodeurl" target="_blank">
                  <img
                    src="@/assets/icon_discord.png"
                    class="ml-8"
                    style="width: 26px"
                  />
                </a>
                <a :href="teleurl" target="_blank">
                  <img
                    src="@/assets/icon_telegram.png"
                    class="ml-8"
                    style="width: 30px"
                  />
                  <a :href="twitterurl" target="_blank">
                    <img
                      src="@/assets/icon_twitter.png"
                      class="ml-8"
                      style="width: 30px"
                    />
                  </a>
                  <a :href="`${mediumurl}`" target="_blank">
                    <img
                      src="@/assets/icon_medium.png"
                      class="ml-8"
                      style="width: 35px"
                    />
                  </a>
                </a>
              </a>
            </div>
          </div>
        </div>

        <div class="footInfo d-flex flex-column align-center">
          <Logo height="48px" width="113px" />
          <div class="copyright mt-6">
            Copyright ©2019-{{ fullYear }} Uptick Network. All rights reserved.
          </div>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script lang="js">
import Logo from "@/components/footLogo/index.vue";
import { Date } from "core-js";
// import api from "@/api";

  export default {
    name: "Footers",
    components: { Logo },
    props: {},
    data: function() {
      return {
        fullYear:'',
        height:200,
        discodeurl:'https://discord.gg/dYQ2gC5Dfu',
        mediumurl:'https://uptickproject.medium.com',
        twitterurl:'https://twitter.com/Uptickproject',
        teleurl:'https://t.me/uptickproject',
        mailurl:'upward@uptickprojecct.com'
      };
    },
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    watch:{
      isMobile(value){
        console.log("value",value);
        if(value){
          this.height=280
        }else{
          this.height=200
        }
      }
    },
  mounted() {
      this.fullYear = new Date().getFullYear()
      if(this.isMobile)
      this.height=280


    },
    methods: {
    }
  };
</script>

<style lang="scss" scoped>
.footer {
  background-image: linear-gradient(#812acb, #812acb),
    linear-gradient(#6c1eaf, #6c1eaf);
  background-blend-mode: normal, normal;
}
.information {
  .tech {
    font-family: Helvetica;
    text-decoration: underline;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
  }
}
.line {
  width: 1px;
  height: 20px;
  border: solid 0.5px #ffffff;
}
.contract {
  width: 140px;
  font-family: Helvetica;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
}
.copyright {
  font-family: AvenirNext-Medium;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 13px;
  letter-spacing: 0px;
  color: #ffffff;
}
.mobile {
  .contant-main {
    display: flex !important;
    flex-direction: column !important;
  }
  .copyright {
    margin-left: 0px !important;
    font-size: 12px;
    margin-top: 15px;
  }
  .contract {
    width: 90px;
    margin-top: 40px;
    margin-bottom: 18px;
  }
  .information {
    display: flex !important;
    flex-direction: column !important;
  }
  .mediaInfo {
    display: flex !important;
    flex-direction: column !important;
  }
  .footInfo {
    display: flex !important;
    flex-direction: column !important;
  }
}
</style>
